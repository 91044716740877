import React, { lazy, Suspense } from "react";
import Spinner from 'react-bootstrap/Spinner';
import './App.css';


// Lazy Load each component
const Navi = lazy(() => import("./components/navbar"));
const Intro = lazy(() => import("./components/intro"));
const Header = lazy(() => import("./components/header"));
const Leistungen = lazy(() => import("./components/leistungen"));
const Slider = lazy(() => import("./components/slider"));
const About = lazy(() => import("./components/about"));
const Contact = lazy(() => import("./components/contact"));
const Footer = lazy(() => import("./components/footer"));
const ScrollToTop = lazy(() => import("./components/backtotop"));


function App() {
  return (
    <div className="App">
      <Suspense fallback={<CustomSpinner />}>
        <Navi />
        <Intro />
        <Header />
        <Leistungen />
        <Slider />
        <About />
        <Contact />
        <Footer />
        <ScrollToTop />
      </Suspense>
    </div>
  );
}

// CustomSpinner-Komponente
const CustomSpinner = () => (
  <div className="custom-spinner">
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Laden...</span>
    </Spinner>
  </div>
);

export default App;
